import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtro'
})
export class FiltroPipe implements PipeTransform {

  cuantos=1;
  transform(arreglo: any[], texto: string, columna: string,otro: any[]): any[] {

    
    console.log('Pipe:' + texto);

    texto = texto.toLowerCase();

    if(texto === '')
      return arreglo;

      if(texto.length > 2)  
      {
        return  otro.filter( item=>{
                 return item[columna].toLowerCase().includes(texto);
         })
      }
      else 
        return arreglo;
  }

}
