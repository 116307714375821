import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

 const Rutas: Routes = [    
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'config',
    loadChildren: () => import('./config/config.module').then( m => m.ConfigPageModule)
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: '',
    //redirectTo: 'folder/Inbox',
    redirectTo: 'login',
    pathMatch: 'full'
  },  
  {
    path: 'opc-inicio',
    loadChildren: () => import('./opc-inicio/opc-inicio.module').then( m => m.OpcInicioPageModule)
  },{
    path: 'ListaProd',
    loadChildren: () => import('./lista-productos/lista-productos.module').then( m => m.ListaProductosPageModule)
  }
  ,{
    path: 'BuscaProd',
    loadChildren: () => import('./busca-prod/busca-prod.module').then( m => m.BuscaProdPageModule)
  }
  ,{
    path: 'Carrito',
    loadChildren: () => import('./carrito/carrito.module').then( m => m.CarritoPageModule)
  }
  ,{
    path: 'Registro',
    loadChildren: () => import('./Registro/Registro.module').then( m => m.RegistroPageModule)
  }
  ,{
    path: 'Resumen',
    loadChildren: () => import('./resumen-pedido/resumen-pedido.module').then( m => m.ResumenPedidoPageModule)
  }
  ,{
    path: 'ResumenFinal',
    loadChildren: () => import('./resumen-final/resumen-final.module').then( m => m.ResumenFinalPageModule)
  }
  ,{
    path: 'MisPedidos',
    loadChildren: () => import('./mis-pedidos/mis-pedidos.module').then( m => m.MisPedidosPageModule)
  }
  ,{
    path: 'DetPedido',
    loadChildren: () => import('./detalle-pedido/detalle-pedido.module').then( m => m.DetallePedidoPageModule)
  }
  ,{
    path: 'DetProducto',
    loadChildren: () => import('./det-producto/det-producto.module').then( m => m.DetProductoPageModule)
  }
  ,{
    path: 'DetRegistro',
    loadChildren: () => import('./det-registro/det-registro.module').then( m => m.DetRegistroPageModule)
  }
  ,{
    path: 'AsignaImg',
    loadChildren: () => import('./asigna-imagen/asigna-imagen.module').then( m => m.AsignaImagenPageModule)
  }
  ,{
    path: 'InveFis',
    loadChildren: () => import('./inve-fisico/inve-fisico.module').then( m => m.InveFisicoPageModule)
  }
  ,{
    path: 'DetBusqueda',
    loadChildren: () => import('./ventana-busqueda/ventana-busqueda.module').then( m => m.VentanaBusquedaPageModule)
  }
  ,{
    path: 'ConfInveFis',
    loadChildren: () => import('./confirma-inve-fisico/confirma-inve-fisico.module').then( m => m.ConfirmaInveFisicoPageModule)
  }
  ,{
    path: 'ConfEnvioConteo',
    loadChildren: () => import('./confirma-envio-conteo/confirma-envio-conteo.module').then( m => m.ConfirmaEnvioConteoPageModule)
  }
  ,{
    path: 'VentasDia',
    loadChildren: () => import('./rep-ventas/rep-ventas.module').then( m => m.RepVentasPageModule)
  }
  ,{
    path: 'CodigosAut',
    loadChildren: () => import('./codigos-aut/codigos-aut.module').then( m => m.CodigosAutPageModule)
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(Rutas, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
