import { Component } from '@angular/core';
import { ServiciosIDEAService } from './services/servicios-idea.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  
  
  constructor(private serviciosIDEA: ServiciosIDEAService) {}

  Opciones: any[];
  rootPage: any;

  CargaOpcionesMenu(){
    this.serviciosIDEA.CargoOpcionesDeMenu().subscribe(
      (resultado: any) => {
        this.Opciones = resultado;  
       },
       (error) =>{
        console.error(error);
       }
       );         
  }
  ngOnInit() {
    
    this.CargaOpcionesMenu(); 
}   

openPage(p1) {
  this.rootPage = p1.component;
}

    toggleDetails(p) {
      if (p.showDetails) {
          p.showDetails = false;
          p.icon = 'ios-arrow-down';
      } else {
          p.showDetails = true;
          p.icon = 'ios-arrow-up';

      }
  }

}
